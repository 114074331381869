
import { Vue, Component, Prop } from 'vue-property-decorator'
import NameRegister from './modules/NameRegister.vue'
import RegisterStepper from './modules/RegisterStepper.vue'

interface ConfirmOpt {
  year: number;
  code:string;
  symbol: Common.Currentcy;
  substrateAddress: string;
  referrer: string
}

@Component({
  components: {
    NameRegister,
    RegisterStepper
  }
})
export default class DomainRegister extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private year = 1
  private showStepper = false
  private code = ''
  private symbol!: Common.Currentcy
  private substrate = ''
  private referrer = ''

  private async handlerConfirm (opt: ConfirmOpt) {
    this.year = opt.year
    this.code = opt.code
    this.symbol = opt.symbol
    this.substrate = opt.substrateAddress
    this.referrer = opt.referrer

    setTimeout(() => {
      this.showStepper = true
    }, 300)
  }
}
