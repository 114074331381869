import { lang } from '@/lang'

export enum TabId {
  REGISTER = 'REGISTER',
  DETAIL = 'DETAIL',
  SUBDOMAINS = 'SUBDOMAINS',
  EDIT_RECORD = 'EDIT_RECORD'
}

export function getTabConfig (): Common.MenuItem<TabId>[] {
  return [
    {
      id: TabId.REGISTER,
      icon: 'book-alt',
      title: lang('DOMAIN_DETAILS_TABS_REGISTET'),
      powers: true
    },
    {
      id: TabId.DETAIL,
      icon: 'clipboard-notes',
      title: lang('DOMAIN_DETAILS_TABS_DETAIL'),
      powers: false
    },
    {
      id: TabId.SUBDOMAINS,
      icon: 'server',
      title: lang('DOMAIN_DETAILS_TABS_SUBDOMAINS'),
      powers: false
    }
  ]
}
