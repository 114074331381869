
import { lang } from '@/lang'
import { getNft } from '@/sdk/domain'
import { Vue, Component, Ref } from 'vue-property-decorator'
import BindNftDialog from '@/views/DomainDetail/modules/BindNft/BindNftDialog.vue'
import { wallet } from '@/utils/wallet'

@Component({
  components: { BindNftDialog }
})
export default class BindNft extends Vue {
  @Ref() private bindNftDialog!: BindNftDialog

  private get text () {
    return {
      title: lang('RECORD_NFT_TITLE'),
      label: lang('RECORD_NFT_LABEL'),
      btn: lang('RECORD_NFT_EDIT_BTN')
    }
  }

  private nft = ''

  private renderDialog = false

  private show () {
    this.bindNftDialog.open()
  }

  private update (newNft: string) {
    this.nft = newNft
  }

  private async getNft () {
    try {
      const domain = this.$route.params.domain
      if (!domain) return
      this.nft = (await getNft(domain) || '').toLowerCase()
    } finally {
      this.renderDialog = true
    }
  }

  private mounted () {
    this.getNft()
  }
}
