
import { lang } from '@/lang'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { checkIsMobile } from '@/utils/device'
import { setNft } from '@/sdk/domain'
import { errorMessage } from '@/utils/message'

@Component({
  components: {}
})
export default class BindNftDialog extends Vue {
  @Prop({ type: String, required: true })
  private nft!: string

  private get text () {
    return {
      title: lang('BIND_NFT_TITLE'),
      btn: lang('RECORD_NFT_BTN')
    }
  }

  private visible = false
  private fetching = false

  private chainType = 'evm'
  private chainId = ''
  private contract = ''
  private tokenId = ''

  private get width () {
    return checkIsMobile() ? '90vw' : '500px'
  }

  close () {
    this.visible = false
  }

  open () {
    this.visible = true
  }

  private async setNft () {
    this.fetching = true

    if (!this.chainId) {
      errorMessage('Chain ID cannot be empty')
      this.fetching = false
      return
    }

    if (!this.contract) {
      errorMessage('Contract address cannot be empty')
      this.fetching = false
      return
    }

    if (!this.tokenId) {
      errorMessage('Token ID cannot be empty')
      this.fetching = false
      return
    }

    try {
      const domain = this.$route.params.domain
      if (!domain) return

      const newNft = `${this.chainType}:${this.chainId}:${this.contract}:${this.tokenId}`.toLowerCase()
      if (newNft === this.nft) {
        this.close()
        return
      }

      await setNft(domain, this.chainType, Number(this.chainId), this.contract, this.tokenId)
      this.close()
      this.$emit('update', newNft)
    } catch (e) {
      errorMessage('Set NFT fail')
    } finally {
      this.fetching = false
    }
  }

  @Watch('nft', { immediate: true })
  private defaultChange (newValue: string) {
    if (!newValue) return
    const nftInfo = newValue.split(':')
    if (nftInfo.length < 4) return

    this.chainId = nftInfo[1]
    this.contract = nftInfo[2]
    this.tokenId = nftInfo[3]
  }
}
