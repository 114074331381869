
import { Vue, Component, Prop } from 'vue-property-decorator'
import EntryMobile from './EntryMobile.vue'
import EntryConsole from './EntryConsole.vue'

@Component({
  components: {
    EntryMobile,
    EntryConsole
  }
})
export default class DomainRecordEdit extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo
}
