import { render, staticRenderFns } from "./NameDetailsRegistration.vue?vue&type=template&id=707f5bff&scoped=true&"
import script from "./NameDetailsRegistration.vue?vue&type=script&lang=ts&"
export * from "./NameDetailsRegistration.vue?vue&type=script&lang=ts&"
import style0 from "./NameDetailsRegistration.vue?vue&type=style&index=0&id=707f5bff&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "707f5bff",
  null
  
)

export default component.exports