
import RegistForm from '@/components/RegistForm/RegistForm.vue'
import { lang } from '@/lang'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { wallet } from '@/utils/wallet'
import UseCodeDialog from '../components/UseCodeDialog.vue'
import SelectPolkadotAddressDialog from '../components/SelectPolkadotAddressDialog.vue'
import { ensureSetup } from '@/sdk/base'
import { searchDomain } from '@/sdk/queryDomainInfo'
import { verifyDomain } from '@/sdk/verify'
import { errorMessage } from '@/utils/message'
import { isCometUser } from '@/sdk/lib/sdk'

@Component({
  components: {
    UseCodeDialog,
    SelectPolkadotAddressDialog
  }
})
export default class NameRegister extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get text () {
    return {
      title: lang('NAME_REGISTER_TITLE'),
      buttonConfirm: lang('NAME_REGISTER_REGISTER'),
      buttonUseCode: lang('NAME_REGISTER_USE_CODE'),
      referrerTitle: lang('NAME_REGISTER_REFERRER_TITTLE'),
      referrerLabel: lang('NAME_REGISTER_REFERRER_LABEL'),
      referrerPlaceholder: lang('NAME_REGISTER_REFERRER_PLACEHOLDER')
    }
  }

  private loading = false

  private fetchingPrice = true

  private referrer = ''

  private symbol: Common.Currentcy = 'GLMR'

  @Ref() private registForm!: RegistForm
  @Ref() private useCodeDialog!: UseCodeDialog
  @Ref() private selectPolkadotAddress!: SelectPolkadotAddressDialog

  private handleSymbolChange (symbol: Common.Currentcy) {
    this.symbol = symbol
  }

  private async showRedeemCodeDialog () {
    await ensureSetup(true)
    this.useCodeDialog.setVisible(true)
  }

  private async showSelectPolkadotAddressDialog () {
    await ensureSetup(true)
    this.selectPolkadotAddress.setVisible(true)
  }

  private async handlerRegist () {
    if (this.referrer) {
      if (verifyDomain(this.referrer)) {
        errorMessage('Invalid referrer')
        return
      }

      // 判断referrer是否合法
      const info = await searchDomain(this.referrer)

      if (!info || info.status === 'available') {
        errorMessage('Invalid referrer')
        window.localStorage.removeItem('referrer')
        return
      }

      // 判断是否邀请自己
      if (info.owner?.toLowerCase() === wallet.accountId.toLowerCase()) {
        errorMessage('Can not invite yourself')
        window.localStorage.removeItem('referrer')
        return
      }
    }

    if (this.symbol === 'DOT' || this.symbol === 'KSM') {
      this.showSelectPolkadotAddressDialog()
      return
    }

    if (this.registForm.year >= 1) {
      //  this.$emit('confirm', this.registForm.year, '', this.symbol, '')
      this.$emit('confirm', {
        year: this.registForm.year,
        code: '',
        symbol: this.symbol,
        substrateAddress: '',
        referrer: this.formatReferrer(this.referrer)
      })
    }
  }

  private handlerCodeConfirm (code:string) {
    if (this.registForm.year >= 1 && code) {
      // 邀请码只能注册一年
      // this.$emit('confirm', 1, code, this.symbol, '')
      this.$emit('confirm', {
        year: 1,
        code: code,
        symbol: this.symbol,
        substrateAddress: '',
        referrer: '' // 兑换码不参与返利
      })
    }
  }

  private handlerSubstrate (address:string) {
    // this.$emit('confirm', this.registForm.year, '', this.symbol, address)
    this.$emit('confirm', {
      year: this.registForm.year,
      code: '',
      symbol: this.symbol,
      substrateAddress: address,
      referrer: this.formatReferrer(this.referrer)
    })
  }

  private formatReferrer (referrer: string) {
    referrer = referrer.trim()
    if (!referrer) return ''

    referrer = referrer.toLowerCase()
    referrer = referrer.replace('.dot', '')
    return referrer + '.dot'
  }

  private freezeReferrer = false
  private async autoReferrer () {
    const referrer = window.localStorage.getItem('referrer') || ''
    if (referrer) {
      try {
        const referrerJson: [string, number] = JSON.parse(referrer)
        const now = Date.parse(new Date().toString())
        if (now <= referrerJson[1]) {
          this.referrer = referrerJson[0]
          if (await isCometUser('')) {
            this.freezeReferrer = true
          }
        } else {
          window.localStorage.removeItem('referrer')
        }
      } catch (e) {
        window.localStorage.removeItem('referrer')
      }
    }
  }

  private mounted () {
    setTimeout(() => {
      this.autoReferrer()
    }, 1100)
  }
}
