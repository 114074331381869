
import { Vue, Component, Prop } from 'vue-property-decorator'
import NameDetails from './modules/NameDetails/Main.vue'
import NameRecord from './modules/NameRecord/Main.vue'

@Component({
  components: {
    NameDetails,
    NameRecord
  }
})
export default class DomainDetail extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo
}
