import { Component } from 'vue'
import { TabId } from './tabs'
import DomainDetail from '@/views/DomainDetail/Main.vue'
import DomainRegister from '@/views/DomainRegister/Main.vue'
import DomainRecordEdit from '@/views/DomainRecordEdit/Main.vue'
import SubDomain from '@/views/SubDomain/Main.vue'

export const subPages: Record<string, Component<unknown, unknown, unknown, unknown>> = {
  [TabId.DETAIL]: DomainDetail,
  [TabId.REGISTER]: DomainRegister,
  [TabId.EDIT_RECORD]: DomainRecordEdit,
  [TabId.SUBDOMAINS]: SubDomain
}
