
import { lang } from '@/lang'
import { getExpireDate } from '@/sdk/domain'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import Renew from '../../dialog/Renew.vue'
import { dateFormat } from '@/utils/common'
import { wallet } from '@/utils/wallet'

@Component({
  components: {
    Renew
  }
})
export default class NameDetailsExpires extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get text () {
    return {
      label: lang('DOMAIN_NAME_DETAILS_EXPIRATION'),
      renew: lang('DOMAIN_NAME_DETAILS_BUTTON_RENEW'),
      remind: lang('DOMAIN_NAME_DETAILS_BUTTON_REMIND')
    }
  }

  private loading = false
  private expireDate: Date | null = null

  @Watch('baseInfo', { immediate: true })
  private async getExpireDate () {
    this.loading = true
    await getExpireDate(this.baseInfo.domain)
      .then(date => (this.expireDate = date))
      .catch(() => 0)
      .finally(() => (this.loading = false))
  }

  private get dateLabel () {
    if (this.expireDate) {
      return dateFormat(this.expireDate)
    }
    return '-'
  }

  private get showActions () {
    return (this.baseInfo.isMine || this.baseInfo.operator.toLowerCase() === wallet.accountId) && !this.baseInfo.isSubDomain
  }

  @Ref() private renewDialog!: Renew

  private openRenewDialog () {
    this.renewDialog.open()
  }
}
