
import { lang } from '@/lang'
import { setRecord } from '@/sdk/domain'
import { errorMessage, successMessage } from '@/utils/message'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EditFooter extends Vue {
  @Prop({ type: Function, required: true })
  private getModifyData!: () => Common.DomainRecord

  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get text () {
    return {
      intro: lang('EDIT_RECORD_FOOTER_INTRO'),
      buttonCancel: lang('EDIT_RECORD_BUTTON_CANCEL'),
      buttonConfirm: lang('EDIT_RECORD_BUTTON_CONFIRM')
    }
  }

  private loading = false

  private async handlerConfirm () {
    const data = this.getModifyData()
    this.loading = true
    await setRecord(this.baseInfo.domain, data)
      .then((flag) => {
        if (flag) {
          successMessage(lang('MESSAGE_EDIT_RECORD_SUCCESS'))
          this.handlerGoBack()
        }
      })
      .catch((e) => {
        console.log(e)
        errorMessage(e.message)
      })
      .finally(() => (this.loading = false))
  }

  private handlerGoBack () {
    this.$router.push(`/details/${this.$route.params.domain}`)
  }
}
