
import { lang } from '@/lang'
import { getRecord } from '@/sdk/domain'
import { TabId } from '@/views/DomainDetailsFrame/config/tabs'
import { Vue, Component, Prop } from 'vue-property-decorator'
import BindNft from '@/views/DomainDetail/modules/BindNft/Main.vue'

@Component({
  components: { BindNft: BindNft }
})
export default class NameRecord extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get text () {
    return {
      title: lang('DOMAIN_RECORD_TITLE'),
      actionText: lang('DOMAIN_RECORD_ACTION_TEXT')
    }
  }

  private get showAction () {
    return this.baseInfo.isMine
  }

  private loading = true
  private model: Common.DomainRecord | null = null

  private async getRecord () {
    this.loading = true
    await getRecord(this.baseInfo.domain)
      .then((data) => (this.model = data))
      .catch(() => 0)
      .finally(() => (this.loading = false))
  }

  mounted (): void {
    this.getRecord()
  }

  private handelEdit () {
    this.$router.push({
      path: this.$route.path,
      query: {
        tab: TabId.EDIT_RECORD
      }
    })
      .catch(e => console.log('[router]:' + e.message))
  }
}
