
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class NameDetailsParent extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get title () {
    return lang('DOMAIN_NAME_DETAILS_PARENT')
  }

  private get value () {
    return this.baseInfo.parentDomain || 'DOT'
  }
}
