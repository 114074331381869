
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Keyring } from '@polkadot/keyring'

@Component({})
export default class PolkadotAddressDropdrown extends Vue {
  @Prop({ type: Array, default: [] })
  private addressList!: Array<{name: string, address: string}>

  @Prop({ type: String, default: 'DOT' })
  private symbol!: string

  selected = this.addressList[0]

  private handlerCommand (addressItem: {name: string, address: string}) {
    this.selected = addressItem
  }

  private get addressListFormat () {
    const keyring = new Keyring()
    if (this.symbol === 'DOT') {
      return this.addressList.map(item => {
        const formatAddress = keyring.encodeAddress(item.address, 0)
        return { address: formatAddress, name: item.name }
      })
    }

    if (this.symbol === 'KSM') {
      return this.addressList.map(item => {
        const formatAddress = keyring.encodeAddress(item.address, 2)
        return { address: formatAddress, name: item.name }
      })
    }

    return this.addressList
  }

  private shotAddress (address: string) {
    return address.substr(0, 30) + ' ... ' + address.substr(-4)
  }
}
