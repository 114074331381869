
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TransferRegistration extends Vue {
  @Prop(Function) private confirmCallback!: (value: string, unloading: () => void, done: () => void) => unknown

  private get width () {
    if (this.$siteConfig.isMobile) {
      return '320px'
    }
    return '425px'
  }

  private get text () {
    return {
      title: lang('TRANSFER_DIALOG_TITLE'),
      label: lang('TRANSFER_DIALOG_LABEL'),
      placeholder: lang('TRANSFER_DIALOG_PLACEHOLDER'),
      buttonConfirm: lang('TRANSFER_DIALOG_BUTTON_CONFIRM'),
      buttonCancel: lang('TRANSFER_DIALOG_BUTTON_CANCEL')
    }
  }

  private visible = false
  private inputValue = ''
  private inputSource = ''
  private loading = false

  open (inputValue: string): void {
    this.inputValue = inputValue
    this.inputSource = inputValue
    this.visible = true
  }

  private get disabled () {
    if (!this.inputValue) return true
    if (this.inputValue === this.inputSource) return true
    return false
  }

  private async handlerConfirm () {
    if (this.disabled) return
    if (!this.confirmCallback) return
    const unloading = () => (this.loading = false)
    const done = () => (this.visible = false)

    this.loading = true
    this.confirmCallback(this.inputValue, unloading, done)
  }
}
