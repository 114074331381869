
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class EditPanel extends Vue {
  @Prop(String) private title!: string
  @Prop(Boolean) private hideHeader!: boolean

  @Prop(Boolean) private editable!: boolean

  private checked = false

  private get showHeader () {
    if (this.hideHeader) return false
    if (this.title) return true
    return false
  }

  public setStatus (status: boolean) {
    this.checked = status
  }

  @Watch('checked')
  statusChange (val: boolean): void {
    this.$emit('statusChange', val)
  }
}
