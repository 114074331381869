
import { searchDomain } from '@/sdk/domain'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { getTabConfig } from './config/tabs'
import { subPages } from './config/subpages'
import { EventBus } from '@/utils/event'
import { errorMessage } from '@/utils/message'
import DomainItemInfo from '@/views/SearchResult/components/DomainItemInfo.vue'

@Component({
  components: {
    ...subPages,
    DomainItemInfo
  }
})
export default class DomainDetailsFrame extends Vue {
  private get domain () {
    return this.$route.params.domain
  }

  private domainBaseInfo: Common.Domain.BaseInfo | null = null
  private loading = false

  private async getDomainBaseInfo () {
    this.loading = true
    await searchDomain(this.domain, true)
      .then(data => (this.domainBaseInfo = data))
      .catch(e => {
        if (e.message === 'User reject request') {
          errorMessage('User reject the chain switch request, please choose a supported network to continue.')
        }
        throw e
      })
      .finally(() => (this.loading = false))
  }

  @Watch('domain', { immediate: true })
  private async onDomainChange () {
    await this.getDomainBaseInfo()
  }

  private get tabConfig () {
    const tabs = getTabConfig()
    const powers = !this.domainBaseInfo?.owner
    return tabs.filter(item => item.powers === powers)
  }

  private get tabSelected () {
    return (this.$route.query.tab as string) || this.tabConfig[0].id
  }

  private set tabSelected (tab) {
    this.$router.push({
      path: this.$route.path,
      query: { tab }
    }).catch(e => console.log('[router]:' + e.message))
  }

  private get componentName () {
    const { tabSelected } = this
    if (tabSelected in subPages) {
      return tabSelected
    }

    return ''
  }

  private get hideTab () {
    return !!(this.componentName && !this.tabConfig.some(v => v.id === this.componentName))
  }

  private reFetch (force = false) {
    // 强行刷新
    if (force) {
      this.getDomainBaseInfo()
      return
    }

    // 详情页，用于决定是否显示编辑按钮
    if (this.tabSelected === 'DETAIL') {
      this.getDomainBaseInfo()
      return
    }

    // 编辑、注册界面不需要重新获取信息以免丢失已经编辑的内容
    if (!this.$route.query.tab) return

    this.getDomainBaseInfo()
  }

  mounted (): void {
    this.$eventBus.on(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.reFetch)
    this.$once('hook:beforeDestroy', () => {
      this.$eventBus.off(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.reFetch)
    })
  }
}
