
import RecordForm from '@/components/RecordForm/RecordForm.vue'
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import { getRecord } from '@/sdk/domain'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import EditFooter from './EditFooter.vue'

@Component({
  components: {
    EditFooter
  }
})
export default class EditPanel extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private loading = true
  private model: Common.DomainRecord | null = null

  private async getModel () {
    this.loading = true
    await getRecord(this.baseInfo.domain)
      .then((data) => {
        this.model = data
        this.loading = false
        console.log(this.model)
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => (this.loading = false))
  }

  async mounted (): Promise<void> {
    this.getModel()
  }

  private get title () {
    return lang('EDIT_RECORD_TITLE')
  }

  @Ref() private recordForm!: RecordForm

  getModifyData (): Common.DomainRecord {
    return this.recordForm.getModifyData()
  }
}
