
import { lang } from '@/lang'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import NameDetailsParent from './NameDetailsParent.vue'
import NameDetailsRegistration from './NameDetailsRegistration.vue'
import NameDetailsController from './NameDetailsController.vue'
import NameDetailsExpires from './NameDetailsExpires.vue'
import NameDetailsResolver from './NameDetailsResolver.vue'

@Component({
  components: {
    NameDetailsRegistration,
    NameDetailsController,
    NameDetailsExpires,
    NameDetailsResolver,
    NameDetailsParent
  }
})
export default class NameDetails extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private moduleList = [
    NameDetailsParent,
    NameDetailsRegistration,
    NameDetailsController,
    NameDetailsExpires,
    NameDetailsResolver
  ]

  @Watch('baseInfo', { immediate: true })
  private baseInfoChang (val:Common.Domain.BaseInfo) {
    if (!val.owner || val.owner === '0x0000000000000000000000000000000000000000') {
      this.$router.replace(this.$route.path)
    }
  }

  private get title () {
    return lang('NAME_DETAILS_TITLE')
  }
}
