
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({})
export default class EditFooter extends Vue {
  @Prop({ type: String, default: '' })
  private tips!:string

  @Prop({ type: String, default: '' })
  private confirmText!:string

  private get text () {
    return {
      buttonCancel: lang('EDIT_RECORD_BUTTON_CANCEL')
    }
  }

  private loading = false

  private async handlerConfirm () {
    this.loading = true
    this.$emit('confirm', this.unload)
  }

  private handlerGoBack () {
    this.$emit('cancel')
  }

  private unload ():void {
    this.loading = false
  }
}
