
import RegistForm from '@/components/RegistForm/RegistForm.vue'
import SelectPolkadotAddressDialog from '@/views/DomainRegister/components/SelectPolkadotAddressDialog.vue'
import { lang } from '@/lang'
import { renewDomain, SubstrateRenewDomain, otherChainRenewDomain, usdtRenewDomain } from '@/sdk/domain'
import { EventBus } from '@/utils/event'
import { errorMessage, successMessage } from '@/utils/message'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
  components: {
    SelectPolkadotAddressDialog
  }
})
export default class Renew extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get text () {
    return {
      title: lang('RENEW_DIALOG_TITLLE'),
      buttonConfirm: lang('RENEW_DIALOG_CONFIRM')
    }
  }

  private visible = false
  private loading = false
  private fetchingPrice = true
  private symbol = 'DOT'

  private get width () {
    if (this.$siteConfig.isMobile) {
      return 'calc(100% - 20px)'
    }
    return '671px'
  }

  @Ref() private registForm!: RegistForm
  @Ref() private selectPolkadotAddress!: SelectPolkadotAddressDialog

  private async substrateRenew (substrateAddress: string) {
    this.loading = true
    await SubstrateRenewDomain(
      this.baseInfo.domain,
      this.symbol.toLowerCase() as 'dot' | 'ksm',
      this.registForm.year,
      substrateAddress)
      .then(() => {
        successMessage(lang('MESSAGE_RENEW_SUCCESS'))
        this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
        this.visible = false
      })
      .catch((e) => {
        console.error('[app-renewDomain]' + JSON.stringify(e))
        errorMessage(lang('MESSAGE_RENEW_ERROR'))
      })
      .finally(() => (this.loading = false))
  }

  private async normalRenew () {
    this.loading = true
    await renewDomain(this.baseInfo.domain, this.registForm.year)
      .then(() => {
        successMessage(lang('MESSAGE_RENEW_SUCCESS'))
        this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
        this.visible = false
      })
      .catch((e) => {
        console.error('[app-renewDomain]' + JSON.stringify(e))
        errorMessage(lang('MESSAGE_RENEW_ERROR'))
      })
      .finally(() => (this.loading = false))
  }

  private async otherChainRenew () {
    this.loading = true
    await otherChainRenewDomain(this.baseInfo.domain, this.symbol as 'BNB' | 'MATIC', this.registForm.year)
      .then(() => {
        successMessage(lang('MESSAGE_RENEW_SUCCESS'))
        this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
        this.visible = false
      })
      .catch((e) => {
        console.error('[app-renewDomain]' + JSON.stringify(e))
        errorMessage(lang('MESSAGE_RENEW_ERROR'))
      })
      .finally(() => (this.loading = false))
  }

  private async usdtRenew () {
    this.loading = true
    await usdtRenewDomain(this.baseInfo.domain, this.symbol as 'USDT_BSC' | 'USDT_POLYGON', this.registForm.year)
      .then(() => {
        successMessage(lang('MESSAGE_RENEW_SUCCESS'))
        this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
        this.visible = false
      })
      .catch((e) => {
        console.error('[app-renewDomain]' + JSON.stringify(e))
        errorMessage(lang('MESSAGE_RENEW_ERROR'))
      })
      .finally(() => (this.loading = false))
  }

  private async handlerClick () {
    this.symbol = this.registForm.symbol
    if (this.symbol === 'DOT' || this.symbol === 'KSM') {
      this.selectPolkadotAddress.setVisible(true)
    } else if (this.symbol === 'GLMR') {
      await this.normalRenew()
    } else if (this.symbol === 'BNB' || this.symbol === 'MATIC') {
      await this.otherChainRenew()
    } else if (this.symbol === 'USDT_BSC' || this.symbol === 'USDT_POLYGON') {
      await this.usdtRenew()
    }
  }

  open (): void {
    this.visible = true
  }
}
