
import { lang } from '@/lang'
import { createSubdomain, batchBurn, getSubdomains, checkEthAddress, getExpireDate } from '@/sdk/domain'
import { errorMessage, successMessage } from '@/utils/message'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import SubDomainItem from './components/SubDomainItem.vue'
import { wallet } from '@/utils/wallet'
import { dateFormat, delay } from '@/utils/common'
import EditFooter from './components/CandidateSubDomainList/EditFooter.vue'
import EditPanel from '@/components/Panel/EditPanel.vue'
import { EventBus } from '@/utils/event'

@Component({
  components: {
    EditPanel,
    SubDomainItem,
    EditFooter
  }
})
export default class SubDomainPanel extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  @Ref() private subDomainItem!: SubDomainItem[]

  @Ref() private editPanel!: EditPanel

  private get text () {
    return {
      title: lang('SUBDOMAIN_TITLE'),
      buttonAdd: lang('SUBDOMAIN_BUTTON_ADD'),
      buttonEdit: lang('SUBDOMAIN_BUTTON_EDIT'),
      nodata: lang('SUBDOMAIN_NO_DATA'),
      tips: lang('SUBDOMAIN_EDIT_TIP'),
      buttonConfirm: lang('SUBDOMAIN_DELETE')
    }
  }

  private get showAction () {
    return this.baseInfo.domain.split('.').length === 2 &&
      (this.baseInfo.isMine || this.baseInfo.operator.toLowerCase() === wallet.accountId)
  }

  private subDomainList: Common.Domain.BaseInfoBackend[] = []

  private loading = true

  // 编辑模式
  private editMode = false

  private selectedSubDomainIndex: Set<number> = new Set()

  private async loadList (mustChange?: boolean): Promise<any> {
    this.loading = true
    const newList = await getSubdomains(this.baseInfo.domain)
    if (mustChange) {
      if (newList.length === this.subDomainList.length) {
        await delay(2000)
        return await this.loadList(mustChange)
      }
    }

    this.subDomainList = newList
    this.loading = false
  }

  mounted (): void {
    this.loadList()
  }

  // 收集编辑结果
  private selectedStatus (status: boolean, index: number) {
    if (status) {
      this.selectedSubDomainIndex.add(index)
    } else {
      this.selectedSubDomainIndex.delete(index)
      this.editPanel.setStatus(false)
    }
  }

  // 全选、全不选
  private toggleSelect (selectAll: boolean) {
    if (selectAll) {
      const all = Object.keys(this.subDomainList).map(item => Number(item))
      this.selectedSubDomainIndex = new Set(all)
      this.subDomainItem.forEach(item => {
        item.setSelect(true)
      })
    } else {
      this.selectedSubDomainIndex.clear()
      this.subDomainItem.forEach(item => {
        item.setSelect(false)
      })
    }
  }

  private async handlerDeleteSubDomain (unload:()=>void) {
    if (!this.selectedSubDomainIndex.size) {
      unload()
      return
    }

    const deleteList:string[] = []
    this.subDomainList.forEach((domainInfo, index) => {
      if (this.selectedSubDomainIndex.has(index)) {
        deleteList.push(domainInfo.name)
      }
    })

    try {
      await batchBurn(deleteList)
      successMessage(lang('MESSAGE_DELETE_SUBDOMAIN_SUCCESS'))
      this.editPanel.setStatus(false)
      this.selectedSubDomainIndex.clear()
      await this.loadList(true)
    } catch (e) {
      console.error(e)
      errorMessage(lang('MESSAGE_DELETE_SUBDOMAIN_ERR'))
    } finally {
      unload()
    }
  }

  private gotoBatchMint () {
    this.$eventBus.emit(EventBus.Types.SUBDOMAIN_ADD_BATCH, true)
  }
}
