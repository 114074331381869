
import { lang } from '@/lang'
import { setRegistration, checkEthAddress } from '@/sdk/domain'
import { EventBus } from '@/utils/event'
import { errorMessage, successMessage } from '@/utils/message'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import TransferRegistration from '../../dialog/TransferRegistration.vue'

@Component({
  components: {
    TransferRegistration
  }
})
export default class NameDetailsRegistration extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private loading = false

  private get showActions () {
    return this.baseInfo.isMine
  }

  private get label () {
    return lang('DOMAIN_NAME_DETAILS_OWNER')
  }

  private get actionText () {
    return lang('DOMAIN_NAME_DETAILS_BUTTON_TRANSFER')
  }

  @Ref() private transferDialog!: TransferRegistration

  private openTransferDialog () {
    this.transferDialog.open(this.baseInfo.owner as string)
  }

  private async handlerTransfer (value: string, unloading: () => void, done: () => void) {
    value = value.trim()

    if (!checkEthAddress(value) && !value.endsWith('.dot')) {
      errorMessage(lang('MESSAGE_ADDRESS_INVALID_ERROR'))
      unloading()
      return
    }

    await setRegistration(this.baseInfo.domain, value)
      .then(() => {
        successMessage(lang('MESSAGE_TRANSFER_SUCCESS'))
        done()
        this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
      })
      .catch((e) => {
        console.error('[app-setRegistration]' + e.message)
        errorMessage(e.message)
      })
      .finally(unloading)
  }

  created (): void {
    console.log('baseInfo', this.baseInfo)
  }
}
