
import { lang } from '@/lang'
import { Vue, Component, Ref } from 'vue-property-decorator'
import AppCodeInput from '@/components/CodeInput/CodeInput.vue'

@Component({})
export default class UseCodeDialog extends Vue {
  @Ref() private codeInput!: AppCodeInput

  private get text () {
    return {
      title: lang('USECODE_DIALOG_TITLE'),
      intro: lang('USECODE_DIALOG_INTRO'),
      check: lang('NAME_REGISTER_REGISTER')
    }
  }

  private codeValue = ''
  private visible = false
  private checking = false

  private get buttonDisabled () {
    return this.codeValue.length !== 12
  }

  setVisible (visible: boolean): void {
    this.visible = visible
  }

  private onOpened () {
    this.codeInput.setFocus()
  }

  private onClosed () {
    this.codeValue = ''
    this.codeInput.reset()
  }

  private async handlClick () {
    // 插入checkcode(),获取nonce,haxCode
    this.checking = true
    this.$emit('codeConfirm', this.codeValue.toLowerCase())
    this.checking = false
  }
}
