
import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import { eventBus, EventBus } from '@/utils/event'
import PolkadotAddressDropdrown from './PolkadotAddressDropdrown.vue'
import { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp'
import { errorMessage } from '@/utils/message'
import { substrateLogin } from '@/sdk/subStrate'

@Component({
  components: {
    PolkadotAddressDropdrown
  }
})
export default class SelectPolkadotAddressDialog extends Vue {
  private addressList: {name: string, address: string}[] = []

  @Ref() private addressDropdown!: PolkadotAddressDropdrown

  @Prop({ required: true, type: String, default: 'DOT' })
  private symbol!:string

  private visible = false

  private get saveDisable (): boolean {
    return false
  }

  private get text () {
    return {
      title: lang('SUBSTRATE_DIALOG_TITLE'),
      des_1: lang('SUBSTRATE_DIALOG_INTRO').replace('#', this.symbol),
      buttonCancel: lang('REVERRECORD_DIALOG_CANCEL'),
      buttonConfirm: lang('SUBSTRATE_DIALOG_CHECK')
    }
  }

  private get width () {
    if (this.$siteConfig.isMobile) {
      return 'calc(100% - 56px)'
    }

    return '573px'
  }

  async open (symbol: 'DOT' | 'KSM') {
    this.visible = true
    this.symbol = symbol
  }

  private handelCancel () {
    this.visible = false
  }

  handlerConfirm () {
    this.visible = false
    this.$emit('confirm', this.addressDropdown.selected.address)
  }

  private async getPolkadotAddress () {
    const addressList = await substrateLogin()
    console.log('addressList', addressList)

    // dot 钱包没有 账号
    if (!addressList.length) {
      console.error('[app-polkadot-extension]:extension must have at least one account')
      errorMessage('Polkadot extension must have at least one account')
    }

    this.addressList = addressList.map(item => {
      console.log('item', item)
      return { name: item.meta.name || '', address: item.address }
    })
  }

  private mounted () :void {
    // this.getPolkadotAddress()
  }

  public setVisible (visible: boolean): void {
    this.visible = visible
  }

  @Watch('visible')
  private async changeVisible (newValue: boolean) {
    if (newValue) {
      await this.getPolkadotAddress()
    }
  }
}
