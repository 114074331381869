
import { lang } from '@/lang'
import {
  registerDomainSimple,
  registDomainByRedeemCodeAnySimple,
  waitConfirmations,
  registDomainBySubstrateSimple,
  NonMoonbeamRegister,
  usdtRegister
} from '@/sdk/domain'
import { successHappyMessage, errorMessage } from '@/utils/message'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { delay } from '@/utils/common'
import ActionBar from '../components/ActiconBar.vue'
import { minLength } from '@/config/domainLength'
import pioneerRegist from '@/config/pioneerRegist'
import { submitReferrer } from '@/sdk/referrer'

@Component({
  components: { ActionBar }
})
export default class RegisterStepper extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  @Prop({ type: Number, default: 1 })
  private year!: number

  @Prop({ type: String, default: '' })
  private code!: string

  @Prop({ type: String, default: '' })
  private symbol!: string

  @Prop({ type: String, default: '' })
  private substrate!: string

  @Prop({ type: String, default: '' })
  private referrer!: string

  private stepFinish = false

  private stepError = false

  private get stepper (): Common.Step[] {
    return [
      {
        // step one
        title: lang('REGISTER_STEPPER_1_TITLE'),
        description: lang('REGISTER_STEPPER_1_DISC'),
        content: () => {
          console.log('this.substrate', this.substrate)
          console.log('this.code', this.code)
          console.log('this.year', this.year)
          console.log('this.symbol', this.symbol)
          console.log('this.baseInfo', this.baseInfo)
          console.log('this.referrer', this.referrer)
          if (this.code) {
            return this.redeemCodeRegist()
          } else if (this.substrate) {
            return this.substrateRegist()
          } else if (this.symbol === 'GLMR') {
            return this.regist()
          } else if (this.symbol === 'MATIC') {
            return this.nonMoonbeamRegist()
          } else if (this.symbol === 'BNB') {
            return this.nonMoonbeamRegist()
          } else if (this.symbol === 'USDT_BSC') {
            return this.usdtRegist()
          } else if (this.symbol === 'USDT_POLYGON') {
            return this.usdtRegist()
          }
        }
      },
      {
        // step two
        title: lang('REGISTER_STEPPER_2_TITLE'),
        description: lang('REGISTER_STEPPER_2_DISC'),
        content: async (stepData: any) => {
          await waitConfirmations(stepData)
          if (this.referrer && !this.code) {
            await this.handelReferrer(stepData)
          }
        }
      },
      {
        title: lang('REGISTER_STEPPER_3_TITLE'),
        description: lang('REGISTER_STEPPER_3_DISC'),
        content: () => delay(2000)
      }
    ]
  }

  private async regist ():Promise<string> {
    try {
      const hash = await registerDomainSimple(this.baseInfo.domain, this.year)
      return hash
    } catch (e) {
      if (e.code === 4001) {
        errorMessage(e.message)
      }

      if (e.code === -32603) {
        errorMessage('Insufficient funds')
      }
      console.error('[app-registerDomainSimple]' + e.message)
      throw e
    }
  }

  private async redeemCodeRegist (): Promise<string> {
    // 邀请码兑换时间只能是一年
    if (this.year > 1) {
      errorMessage(lang('NAME_REGISTER_REDEEMCODE_TIME_TOO_LONG'))
      throw new Error(lang('NAME_REGISTER_REDEEMCODE_TIME_TOO_LONG'))
    }

    if (this.code) {
      try {
        console.log('domain:', this.baseInfo.domain)
        console.log('code', this.code)
        const hash = await registDomainByRedeemCodeAnySimple(this.baseInfo.domain, this.code)
        return hash
      } catch (e) {
        errorMessage(e.message)
        console.error('[app-registDomainByRedeemCodeAnySimple]' + e.message)
        throw e
      }
    } else {
      throw new Error()
    }
  }

  private async substrateRegist (): Promise<string> {
    if (this.substrate) {
      try {
        console.log('domain:', this.baseInfo.domain)
        console.log('substrate', this.substrate)
        const hash = await registDomainBySubstrateSimple(
          this.baseInfo.domain,
          this.symbol.toLowerCase() as 'dot' | 'ksm',
          this.year,
          this.substrate
        )
        return hash
      } catch (e) {
        errorMessage(e.message)
        console.error('[app-registDomainByRedeemCodeAnySimple]' + e.message)
        throw e
      }
    } else {
      throw new Error('Regist fail')
    }
  }

  private async nonMoonbeamRegist (): Promise<string> {
    try {
      const hash = await NonMoonbeamRegister(this.baseInfo.domain, this.year, this.symbol)
      return hash
    } catch (e) {
      errorMessage(e.message)
      console.error('[app-nonMoonbeamRegist]' + e.message)
      throw e
    }
  }

  private async usdtRegist (): Promise<string> {
    try {
      const hash = await usdtRegister(this.baseInfo.domain, this.year, this.symbol)
      return hash
    } catch (e) {
      errorMessage(e.message)
      console.error('[app-usdtRegist]' + e.message)
      throw e
    }
  }

  private async handelReferrer (txHash: string) {
    try {
      await submitReferrer(this.referrer, this.baseInfo.domain, txHash, this.symbol)
      // 刷新referrer时间
      const referrer = this.referrer.replace('.dot', '') + '.dot'
      const now = Date.parse(new Date().toString())
      const duration = now + (1000 * 60 * 60 * 2) // 2 小时有效
      window.localStorage.setItem('referrer', JSON.stringify([(referrer as string).toLowerCase(), duration]))
    } catch (e) {
      console.error('[app-handelReferrer]' + e.message)
    }
  }
}
