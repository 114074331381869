
import { lang } from '@/lang'
import ShareCard from '@/views/DomainRegister/components/ShareCard.vue'
import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { EventBus, eventBus } from '@/utils/event'

@Component({
  components: { ShareCard }
})
export default class ActionBar extends Vue {
  @Prop({ type: Boolean, default: false })
  private stepFinish!: boolean

  @Prop({ type: Boolean, default: false })
  private stepError!: boolean

  @Prop({ type: String, default: '' })
  private domain!: string

  @Ref() private shareCard!:ShareCard

  private get text () {
    return {
      retry: lang('REGISTER_ACTIONBAR_RETRY'),
      manage: lang('REGISTER_ACTIONBAR_MANAGE'),
      twitter: lang('REGISTER_ACTIONBAR_TWITTER'),
      share: lang('REGISTER_ACTIONBAR_SHARE')
    }
  }

  private reFetch () {
    eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH, true)
  }

  private share () {
    this.shareCard.open()
  }

  private sendTwitter () {
    const messageText = encodeURIComponent(`#PNSisCool @PolkaNameSystem pns.link 🌐 \n\n ${this.domain} is my Web3 domain! \n PNS is a Polkadot decentralized naming system. \n Visit my Web3Profile at: \n ${this.domain}.site \n\n`)
    const address = encodeURIComponent('https://www.pns.link/')
    window.open(`https://twitter.com/intent/tweet?text=${messageText}&url=${address}`)
  }
}
