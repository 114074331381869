
import { Vue, Component, Prop } from 'vue-property-decorator'
import SubDomainPanel from './SubDomainPanel.vue'
import { EventBus } from '@/utils/event'

@Component({
  components: {
    SubDomainPanel,
    MintSubDomainPanel: () => import('./MintSubDomainPanel.vue')
  }
})
export default class SubDomain extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  // 是否增加子域名模式
  private addSubDomainModel = false

  // 是否删除子域名模式
  // private deleteSubDomainModel = false

  private setModel (status: boolean) {
    this.addSubDomainModel = status
  }

  mounted ():void {
    this.$eventBus.on(EventBus.Types.SUBDOMAIN_ADD_BATCH, this.setModel)
    this.$once('hook:beforeDestroy', () => {
      this.$eventBus.off(EventBus.Types.SUBDOMAIN_ADD_BATCH, this.setModel)
    })
  }
}
