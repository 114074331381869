
import { lang } from '@/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SetController extends Vue {
  @Prop(Function)
  private confirmCallback!: (
    value: string,
    unloading: () => void,
    done: () => void,
  ) => unknown

  private get text () {
    return {
      title: lang('SET_CONTROLLER_DIALOG_TITLE'),
      label: lang('SET_CONTROLLER_DIALOG_LABEL'),
      placeholder: lang('SET_CONTROLLER_DIALOG_PLACEHOLDER'),
      buttonConfirm: lang('SET_CONTROLLER_DIALOG_BUTTON_CONFIRM'),
      buttonCancel: lang('SET_CONTROLLER_DIALOG_BUTTON_CANCEL')
    }
  }

  private visible = false
  private inputValue = ''
  private inputSource = ''
  private loading = false

  open (inputValue: string): void {
    this.inputValue = inputValue
    this.inputSource = inputValue
    this.visible = true
  }

  private get disabled () {
    if (!this.inputValue) return true
    if (this.inputValue === this.inputSource) return true
    return false
  }

  private async handlerConfirm () {
    if (this.disabled) return
    if (!this.confirmCallback) return
    const unloading = () => (this.loading = false)
    const done = () => (this.visible = false)

    this.loading = true
    this.confirmCallback(this.inputValue, unloading, done)
  }
}
