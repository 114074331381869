
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import EditPanel from './EditPanel.vue'
import EditFooter from './EditFooter.vue'

@Component({
  components: {
    EditPanel,
    EditFooter
  }
})
export default class DomainRecordEditMobile extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  @Ref() private editPanel!: EditPanel

  private getModifyData () {
    return this.editPanel.getModifyData()
  }
}
