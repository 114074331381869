
import { lang } from '@/lang'
import { checkEthAddress, checksumEthAddress, setOperator } from '@/sdk/domain'
import { EventBus } from '@/utils/event'
import { errorMessage, successMessage } from '@/utils/message'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import SetController from '../../dialog/SetController.vue'
import { wallet } from '@/utils/wallet'

@Component({
  components: {
    SetController
  }
})
export default class NameDetailsController extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private get text () {
    return {
      label: lang('DOMAIN_NAME_DETAILS_OPERATOR'),
      actionText: lang('DOMAIN_NAME_DETAILS_BUTTON_SET')
    }
  }

  private loading = false

  private get showActions () {
    return this.baseInfo.isMine || this.baseInfo.operator.toLowerCase() === wallet.accountId
  }

  @Ref() private setControllerDialog!: SetController

  private openDialog () {
    this.setControllerDialog.open(this.baseInfo.operator)
  }

  private async handlerConfirm (value: string, unloading: () => void, done: () => void) {
    if (!checkEthAddress(value)) {
      errorMessage(lang('MESSAGE_ADDRESS_INVALID_ERROR'))
      unloading()
      return
    }
    value = checksumEthAddress(value.toLowerCase())
    try {
      await setOperator(this.baseInfo.domain, value)
      successMessage(lang('MESSAGE_SET_CONTROLLER_SUCCESS'))
      done()
      this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
    } catch (e) {
      console.error('[app-setOperator]' + JSON.stringify(e))
      errorMessage(lang('MESSAGE_SET_CONTROLLER_ERROR'))
      unloading()
    }
  }
}
