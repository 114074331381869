
import { lang } from '@/lang'
import { getResolver, setResolver, checkEthAddress, checksumEthAddress } from '@/sdk/domain'
import { EventBus } from '@/utils/event'
import { errorMessage, successMessage } from '@/utils/message'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import SetResolverDialog from '../../dialog/SetResolver.vue'

@Component({
  components: {
    SetResolverDialog
  }
})
export default class NameDetailsResolver extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo

  private loading = false
  private resolver = ''

  private get showActions () {
    // todo:lock the feature of set resolver
    // return this.baseInfo.isMine || this.baseInfo.operator.toLowerCase() === wallet.accountId
    return false
  }

  @Watch('baseInfo', { immediate: true })
  private async getResolver () {
    this.loading = true
    await getResolver(this.baseInfo.domain)
      .then(addr => (this.resolver = addr || ''))
      .catch(() => 0)
      .finally(() => (this.loading = false))
  }

  private get text () {
    return {
      label: lang('DOMAIN_NAME_DETAILS_RESOLVER'),
      action: lang('DOMAIN_NAME_DETAILS_BUTTON_SET')
    }
  }

  @Ref() private dialog!: SetResolverDialog

  private openDialog () {
    this.dialog.open(this.resolver)
  }

  private async handlerConfirm (value: string, unloading: () => void, done: () => void) {
    if (!checkEthAddress(value)) {
      errorMessage(lang('MESSAGE_ADDRESS_INVALID_ERROR'))
      unloading()
      return
    }
    value = checksumEthAddress(value.toLowerCase())
    await setResolver(this.baseInfo.domain, value)
      .then(() => {
        successMessage(lang('MESSAGE_SET_RESOLVER_SUCCESS'))
        done()
        this.$eventBus.emit(EventBus.Types.DOMAIN_BASEINFO_REFETCH)
      })
      .catch(() => errorMessage(lang('MESSAGE_SET_RESOLVER_ERROR')))
      .finally(unloading)
  }
}
