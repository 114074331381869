
import { Vue, Component, Prop } from 'vue-property-decorator'
import EditPanel from './EditPanel.vue'

@Component({
  components: {
    EditPanel
  }
})
export default class DomainRecordEditConsole extends Vue {
  @Prop({ type: Object, required: true })
  private baseInfo!: Common.Domain.BaseInfo
}
